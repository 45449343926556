import { Injectable } from '@angular/core';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';

@Injectable({ providedIn: 'root' })
export class ScrollLockService {
  clearAllBodyScrollLocks(): void {
    clearAllBodyScrollLocks();
  }

  disableScroll(targetElement: Element | HTMLElement): void {
    disableBodyScroll(targetElement, {
      reserveScrollBarGap: true,
    });
  }

  enableScroll(targetElement: Element | HTMLElement): void {
    enableBodyScroll(targetElement);
  }
}
