import { Injectable, signal } from '@angular/core';
import { SignalsStoreService } from '@expocab/shared/classes';

interface SidemenuState {
  state: boolean;
}

const INITIAL_STATE: SidemenuState = { state: true };

@Injectable({ providedIn: 'root' })
export class SidemenuService extends SignalsStoreService<SidemenuState> {
  override readonly state = signal<SidemenuState>(INITIAL_STATE);
}
