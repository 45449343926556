import { ChangeDetectorRef, inject } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

export abstract class AbstractFormControl implements ControlValueAccessor {
  private readonly cd = inject(ChangeDetectorRef);
  disabled = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: unknown): void => {};

  onTouched = (): void => {};
  value: unknown;

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: unknown): void {
    this.value = value;
    this.cd.markForCheck();
  }
}
