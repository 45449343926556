import { ViewportRuler } from '@angular/cdk/scrolling';
import {
  DestroyRef,
  Directive,
  HostListener,
  Inject,
  NgZone,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WINDOW } from '@ng-web-apis/common';

import { ScreenWidthService } from './screen-width.service';

@Directive({
  selector: '[expocabScreenWidth]',
  standalone: true,
})
export class ScreenWidthDirective {
  private readonly viewportChange = this.viewportRuler
    .change(500)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(() =>
      this.ngZone.run(() => {
        const innerWidth = this.viewportRuler.getViewportSize().width;
        this.sendSize(innerWidth);
      }),
    );

  constructor(
    @Inject(WINDOW) private readonly windowRef: Window,
    private viewportRuler: ViewportRuler,
    private ngZone: NgZone,
    private screenWidthService: ScreenWidthService,
    private destroyRef: DestroyRef,
  ) {}

  private sendSize(width: number): void {
    this.screenWidthService.setSize(width);
  }

  @HostListener('window:load') onLoad(): void {
    this.sendSize(this.windowRef.innerWidth);
  }
}
