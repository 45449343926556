import { Signal, computed, signal } from '@angular/core';

export class SignalsStoreService<T> {
  readonly state = signal(<T>{});

  /**
   * Получение реактивного состояния из стора по ключу
   * @param key - ключ в объекте состояния
   */
  select<K extends keyof T>(key: K): Signal<T[K]> {
    return computed(() => this.state()[key]);
  }

  /**
   * Сохранение данных в стор по ключу
   * @param key - ключ для устанавливаемого свойства
   * @param data - значение свойства
   */
  set<K extends keyof T>(key: K, data: T[K]): void {
    this.state.update((currentValue) => ({ ...currentValue, [key]: data }));
  }

  /**
   * Сохранение в стор нескольких значений / обновление части стора
   * @param partialState - часть состояния (несколько свойств) для записи в стор
   */
  setState(partialState: Partial<T>): void {
    this.state.update((currentValue) => ({ ...currentValue, ...partialState }));
  }
}
