import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScreenWidthService {
  private readonly screenWidthSubject$: BehaviorSubject<number> =
    new BehaviorSubject<number>(this.windowRef.innerWidth);

  readonly screenWidth$ = this.screenWidthSubject$
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(@Inject(WINDOW) private readonly windowRef: Window) {}

  setSize(width: number): void {
    this.screenWidthSubject$.next(width);
  }
}
