import { Injectable, signal } from '@angular/core';
import { SignalsStoreService } from '@expocab/shared/classes';

interface FiltersState {
  state: boolean;
  visibility?: boolean;
}

const INITIAL_STATE: FiltersState = { state: true, visibility: true };

@Injectable({ providedIn: 'root' })
export class FiltersService extends SignalsStoreService<FiltersState> {
  override readonly state = signal<FiltersState>(INITIAL_STATE);
}
