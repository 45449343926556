import { Injectable, signal } from '@angular/core';
import { SignalsStoreService } from '@expocab/shared/classes';

export interface FilesState {
  currentBusinessProcessId: null | number;
  fileName: string;
}

const INITIAL_STATE: FilesState = {
  currentBusinessProcessId: null,
  fileName: '',
};

@Injectable({ providedIn: 'root' })
export class FilesService extends SignalsStoreService<FilesState> {
  override readonly state = signal<FilesState>(INITIAL_STATE);
}
