import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { BusEventType } from './model';

@Injectable({ providedIn: 'root' })
export class EventBusService {
  private readonly eventBusSubject$ = new Subject<BusEventType>();

  readonly events$ = this.eventBusSubject$.asObservable();

  pushEvent(event: BusEventType): void {
    this.eventBusSubject$.next(event);
  }
}
